import { CardMeta } from "./CardMeta"
import type { Song } from "../types/song"

export interface Card {
  title: string
  songs: Array<Song>
  action: string
  url: string
  show_id: string
  titling: string
  marquee: string
  type: string
  image: string
  index: number
  resource_id: number
  slot_id: number
  starts: string
  ends: string
  reminder: string | null
}

interface CardProps {
  cards: Array<Card>
  onShowDetails: CallableFunction
}

export function Cards({ cards, onShowDetails }: CardProps) {
  function handleShowDetails(e: React.MouseEvent, card: Card) {
    let target = e.target as HTMLElement

    while (target.tagName != "LI") {
      target = target.parentNode as HTMLElement
    }

    // Only trigger details popup for shows and music
    if (!["show", "music"].includes(card.type)) return

    // Don't show details popup for music cards that aren't live or aired
    if (card.type == "music") {
      if (
        !(
          target.classList.contains("live") ||
          target.classList.contains("aired")
        )
      ) {
        return
      }
    }

    onShowDetails(target)
  }

  return (
    <div id="mask">
      <ol
        id="slots"
        className="clearfix"
        style={{
          transitionDuration: "300ms",
        }}
      >
        <span id="tip-indicator">&nbsp;</span>
        {cards.map((card) => {
          let cardTitle = ""

          if (card.type == "ad") {
            cardTitle = card.marquee
          } else if (card.type != "network") {
            cardTitle = card.titling
          }

          const bg = card.image.replace(
            "http://d3aus257jr2ffx.cloudfront.net/images/",
            "",
          )

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <li
              key={card.index}
              data-index={card.index}
              onClick={(e) => handleShowDetails(e, card)}
              style={{
                backgroundImage: `url(http://d3aus257jr2ffx.cloudfront.net/images/${bg})`,
              }}
              className={card.type}
            >
              <CardMeta cardTitle={cardTitle} card={card} />
            </li>
          )
        })}
      </ol>
    </div>
  )
}
