/* eslint-disable jsx-a11y/anchor-is-valid */

interface HeaderProps {
  toggleRadio: () => void
  scheduleIndex: number
  selectSlot: (index: number) => void
}

export function Header({
  toggleRadio,
  scheduleIndex,
  selectSlot,
}: HeaderProps) {
  return (
    <header id="main">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <a id="play-btn" data-icon="p" onClick={toggleRadio}>
        p
      </a>
      <a
        href="/"
        onClick={(e) => {
          e.preventDefault()
          selectSlot(scheduleIndex)
        }}
        id="home"
      >
        <em>REF</em>
        <em>NET</em>
      </a>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          document.body.className = "share"
          // trackScreenView("share")
        }}
        id="share-btn"
        data-icon="g"
      >
        g
      </a>
      <div id="playing">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
        <h1
          id="marquee"
          onClick={(e) => {
            e.preventDefault()
            selectSlot(scheduleIndex)
          }}
        >
          {" "}
        </h1>
      </div>
    </header>
  )
}
