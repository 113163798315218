import type { Card } from "./Cards"

interface CardMetaProps {
  cardTitle: string
  card: Card
}

export function CardMeta({ cardTitle, card }: CardMetaProps) {
  return (
    <span className="meta">
      <h3 key={cardTitle} dangerouslySetInnerHTML={{ __html: cardTitle }}></h3>
      {card.type == "ad" && card.url ? (
        <span key={card.slot_id} className="actions clearfix">
          <a
            href={card.url}
            target="_blank"
            className="action"
            rel="noreferrer"
          >
            {card.action}
          </a>
        </span>
      ) : (
        <h4
          key={card.slot_id}
          data-href={
            card.type == "music"
              ? `/music/${card.index}`
              : `/episodes/${card.resource_id}`
          }
          dangerouslySetInnerHTML={{ __html: card.title }}
        ></h4>
      )}
    </span>
  )
}
