/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react"
import { Spinner } from "spin.js"
import { useLoaderData } from "@remix-run/react"
import classNames from "classnames"
import {
  localize,
  getLiveSlot,
  desktop_spinner_opts,
  popup_spinner_opts,
  seekTo,
} from "../utils"
import { getClientIPAddress } from "remix-utils/get-client-ip-address"
import moment from "moment-timezone"
import type { LoaderFunctionArgs } from "@vercel/remix"
import DetailsPopup from "../components/DetailsPopup"
import { Header } from "~/components/Header"
import { Cards } from "../components/Cards"
import { SleepTimer } from "../components/SleepTimer"
import { ShareModal } from "../components/ShareModal"
import { BottomNav } from "./../components/BottomNav"
import type { Card } from "../components/Cards"
import type { Song } from "../types/song"

export async function loader({ request }: LoaderFunctionArgs) {
  const server = process.env.ADMIN_URL
  const responses = await Promise.all([
    fetch(`${server}/api/schedule.json`),
    fetch(`${server}/api/resources.json`),
    fetch(`${server}/api/v2/slots.json`),
    fetch(`${server}/api/v2/playlist.json`),
    fetch(`${server}/api/shows.json`),
    fetch(`${server}/api/organizations.json`),
  ])
  const schedule = await responses[0].json()
  const resources = await responses[1].json()
  const slots = await responses[2].json()
  let playlist = await responses[3].json()
  const shows = await responses[4].json()
  const organizations = await responses[5].json()
  const basePath = playlist.meta.base_path

  playlist = playlist.collection.map(
    (item: { url?: string; m3u8?: string }) => {
      const obj = { ...item }

      delete obj.url
      delete obj.m3u8

      return obj
    },
  )

  let ip = request.headers.get("CF-Connecting-IP")
  if (ip === null) ip = getClientIPAddress(request)

  const cards = [] as Array<Card>

  for (let i = 0; i < schedule.collection.length; i++) {
    const segment = schedule.collection[i]

    const resource = resources.index[segment.resource_id]
    const slot = slots.index[resource.slot_id]
    const card = {
      title: "",
      songs: [] as Array<Song>,
      action: "",
      url: "",
      show_id: "",
      titling: resource.titling || slot.titling,
      marquee: slot.marquee || resource.marquee,
      type: slot.type,
      image: slot.image,
      index: i,
      resource_id: segment.resource_id,
      slot_id: resource.slot_id,
      starts: segment.starts,
      ends: segment.ends,
      reminder: segment.reminder ? segment.reminder : null,
    }

    if (slot.type == "music") {
      card.title = resources.index[segment.songs[0].resource_id].title
      card.songs = segment.songs.map(function (song_segment: {
        resource_id: string | number
      }) {
        return {
          ...song_segment,
          ...resources.index[song_segment.resource_id],
        }
      })
    } else if (resource.title) {
      card.title = resource.title
    }

    if (slot.type == "ad") {
      card.action = resource.action
      card.url = resource.url
    } else if (slot.type == "show") {
      card.show_id = slot.show_id
    }

    cards.push(card)
  }

  return {
    cards,
    easternSchedule: schedule.collection,
    slots: schedule.slots,
    easternPlaylist: playlist,
    basePath,
    resources,
    shows,
    organizations,
  }
}

enum PlayerStatus {
  IDLE = "idle",
  LOADING = "loading",
  PLAYING = "playing",
  ENDED = "ENDED",
}

export default function Index() {
  const {
    cards,
    easternPlaylist,
    easternSchedule,
    basePath,
    slots,
    resources,
    shows,
    organizations,
  } = useLoaderData<typeof loader>()
  // i of live schedule/playlist slots, and the selected (centered) slot
  const [scheduleIndex, setScheduleIndex] = useState(0)
  const [playlistIndex, setPlaylistIndex] = useState(0)
  const [slotIndex, setSlotIndex] = useState(0)
  const [schedule, setSchedule] = useState(easternSchedule)
  const [playlist, setPlaylist] = useState(easternPlaylist)
  const [detailsTarget, setDetailsTarget] = useState<HTMLElement | undefined>()
  const [isSleepTimerVisible, setIsSleepTimerVisible] = useState(false)
  const [isTicking, setIsTicking] = useState(false)
  const [clockText, setClockText] = useState("")
  const [playerStatus, setPlayerStatus] = useState<PlayerStatus>(
    PlayerStatus.IDLE,
  )

  const playlistIndexRef = useRef(playlistIndex)
  const playerRef = useRef<HTMLAudioElement>(null)

  function resize() {
    const slots = document.querySelectorAll("#slots li")
    const slotsContainer = document.getElementById("slots")
    const slotWidth = (slots[0] as HTMLElement).offsetWidth
    const offset = window.innerWidth / 2 - slotWidth / 2
    const tip = document.getElementById("tip-indicator")

    if (slotsContainer) {
      slotsContainer.style.width = `${slots.length * (slotWidth + 1)}px`
      slotsContainer.style.marginLeft = `-${slotWidth * slotIndex - offset}px`

      if (tip) {
        tip.style.top = "0px"
        tip.style.left = `${slotWidth * scheduleIndex + slotWidth / 2}px`
      }
    }
  }

  useEffect(() => {
    setClockText(moment(schedule[slotIndex].starts).format("h:mm A"))
  }, [schedule, slotIndex, setClockText])

  function selectSlot(index: number) {
    if (+index < 0 || +index > schedule.length) return
    setSlotIndex(index)
    resize()
  }

  function keyboardEvent(event: KeyboardEvent) {
    if (event.key == "ArrowRight") selectSlot(+slotIndex + 1)
    else if (event.key == "ArrowLeft") selectSlot(+slotIndex - 1)
    else if (event.key == " ") toggleRadio()
  }

  let spinner: Spinner

  function startRadio() {
    const playBtn = document.getElementById("play-btn")
    spinner = new Spinner(
      window.innerWidth < 321 ? popup_spinner_opts : desktop_spinner_opts,
    )

    if (playBtn) playBtn.innerHTML = "&nbsp;"
    setPlayerStatus(PlayerStatus.LOADING)
    if (playBtn) spinner.spin(playBtn)

    const updatedPlaylistIndex = +getLiveSlot(playlist)

    setPlaylistIndex(updatedPlaylistIndex)
    playlistIndexRef.current = updatedPlaylistIndex

    // we get the time that has elapsed since the start of the
    // show and apply that to the player
    const currentTime = seekTo(playlist[playlistIndex]?.starts)
    if (playerRef?.current) {
      playerRef.current.src = basePath + playlist[playlistIndex].mp4.hi
      playerRef.current.play()
      playerRef.current.currentTime = currentTime
    }

    // trackPlay(episodeTitle, showTitle, organization, host, type, slotStartTime, slotEndTime)
  }

  function nextRadio() {
    const playBtn = document.getElementById("play-btn")
    spinner = new Spinner(
      window.innerWidth < 321 ? popup_spinner_opts : desktop_spinner_opts,
    )

    if (playBtn) playBtn.innerHTML = "&nbsp;"
    setPlayerStatus(PlayerStatus.LOADING)
    if (playBtn) spinner.spin(playBtn)

    const updatedPlaylistIndex = playlistIndexRef.current + 1
    setPlaylistIndex(updatedPlaylistIndex)
    playlistIndexRef.current = updatedPlaylistIndex

    const url = basePath + playlist[updatedPlaylistIndex].mp4.hi

    // we get the time that has elapsed since the start of the
    // show and apply that to the player
    const currentTime = seekTo(playlist[updatedPlaylistIndex]?.starts)
    if (playerRef.current) {
      playerRef.current.src = url
      playerRef.current && playerRef.current.play()
      playerRef.current.currentTime = currentTime
    }

    // trackPlay(episodeTitle, showTitle, organization, host, type, slotStartTime, slotEndTime)
  }

  function stopRadio() {
    const playBtn = document.getElementById("play-btn")

    if (playerRef) playerRef?.current?.pause()
    if (playBtn) playBtn.innerHTML = "p"

    setPlayerStatus(PlayerStatus.ENDED)

    if (slotIndex === scheduleIndex) {
      setClockText(moment(schedule[slotIndex].starts).format("h:mm A"))
    }

    // trackStop(episodeTitle, showTitle, organization, host, type, episodeDurationSeconds, episodePlayDurationSeconds, playDurationSeconds, slotStartTime, slotEndTime)
  }

  function toggleRadio() {
    if (playerStatus === PlayerStatus.PLAYING) {
      stopRadio()
    } else {
      startRadio()
    }
  }

  function showPopup(e: React.MouseEvent) {
    e.preventDefault()

    let url = window.location.origin

    if (playerStatus === PlayerStatus.PLAYING) {
      stopRadio()
      url += "/#start"
    }

    // todo: browser detection?
    // var height = Browser.name == "safari" ? 502 : 480
    const height = 480

    const pop = window.open(
      url,
      "RefNet Popup Player",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=320,height=" +
        height +
        ",top=50,left=50,screenX=50,screenY=50",
    )

    if (pop) pop.focus()
  }

  function setMarquee() {
    let text
    const liveSlot = cards[scheduleIndex]
    const musicIndex = liveSlot.songs ? playlistIndex - slots[scheduleIndex] : 0

    if (liveSlot.type == "music") {
      text = liveSlot.songs[musicIndex]
        ? Array(4).fill(liveSlot.songs[musicIndex].marquee).join(" :: ")
        : undefined
    } else {
      text = Array(4).fill(liveSlot.marquee).join(" :: ")
    }

    const marquee = document.getElementById("marquee")

    if (!text || marquee?.innerText == text) {
      return
    }

    const playing = document.getElementById("playing")
    let charWidth = 12

    if (playing) charWidth = playing.offsetWidth > 300 ? 40 : 12

    if (marquee) {
      marquee.innerText = text
      marquee.className = "scroll"
      marquee.style.animationDuration = text.length * charWidth * 0.01 + "s"
    }

    const liveCards = document.querySelectorAll("ol#slots li.live")

    if (
      liveCards.length &&
      liveCards[0].classList.contains("music") &&
      liveSlot.songs[musicIndex]
    ) {
      const header = document.querySelector("ol#slots li.live h4")

      if (header) header.innerHTML = liveSlot.songs[musicIndex].title
    }
  }

  useEffect(() => {
    selectSlot(scheduleIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleIndex])

  useEffect(() => {
    const slots = document.querySelectorAll("#slots li")

    for (const i in slots) {
      const slot: HTMLElement = slots[i] as HTMLElement

      if (slotIndex == +i) slot.classList?.add("selected")
      else slot.classList?.remove("selected")
      if (scheduleIndex == +i) slot.classList?.add("live")
      else slot.classList?.remove("live")
      if (scheduleIndex > +i) slot.classList?.add("aired")
      else slot.classList?.remove("aired")
    }

    const marquee = document.getElementById("marquee")

    if (marquee) marquee.innerHTML = cards[scheduleIndex].marquee

    setMarquee()

    resize()

    addEventListener("resize", resize)
    addEventListener("keyup", keyboardEvent)

    return () => {
      window.removeEventListener("resize", resize)
      window.removeEventListener("keyup", keyboardEvent)
    }
  })

  let interval: ReturnType<typeof setInterval>

  useEffect(() => {
    // Localize schedule & playlist based on client timezone
    const tmpSchedule = JSON.parse(JSON.stringify(easternSchedule))
    const tmpPlaylist = JSON.parse(JSON.stringify(easternPlaylist))
    localize(tmpSchedule, new Date().getTimezoneOffset() / 60)
    localize(playlist, new Date().getTimezoneOffset() / 60)
    setSchedule(tmpSchedule)
    setPlaylist(tmpPlaylist)

    const current = +getLiveSlot(tmpSchedule)

    // trackEpisodeFinished(episodeTitle, showTitle, organization, host, type, episodeDurationSeconds, episodePlayDurationSeconds, slotStartTime, slotEndTime)

    setScheduleIndex(current)
    const updatedPlaylistIndex = +getLiveSlot(tmpPlaylist) as number

    setPlaylistIndex(updatedPlaylistIndex)
    playlistIndexRef.current = updatedPlaylistIndex

    selectSlot(current)

    // Update schedule index if needed. Checks once per second
    function tick() {
      const current = getLiveSlot(tmpSchedule)

      if (current != scheduleIndex) {
        setScheduleIndex(+current)
      }
    }

    if (interval) clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    interval = setInterval(tick, 1000)
  }, [])

  // Player event handlers
  const onTimeUpdate = () => {
    if (playerRef.current) {
      const left = playerRef.current.duration - playerRef.current.currentTime

      if (
        slotIndex === scheduleIndex &&
        left > 0 &&
        !playerRef.current.paused
      ) {
        setClockText(
          moment("2015-01-01").startOf("day").seconds(left).format("mm:ss"),
        )
      }
    }
  }

  const onPlaying = () => {
    const playBtn = document.getElementById("play-btn")
    if (playBtn) playBtn.innerHTML = "s"

    setPlayerStatus(PlayerStatus.PLAYING)

    if (spinner) spinner.stop()
  }

  const onEnded = () => nextRadio()

  return (
    <>
      <section
        id="radio"
        className={classNames({
          loading: playerStatus === PlayerStatus.LOADING,
          playing: playerStatus === PlayerStatus.PLAYING,
        })}
      >
        <Header
          toggleRadio={toggleRadio}
          scheduleIndex={scheduleIndex}
          selectSlot={selectSlot}
        />
        <Cards cards={cards} onShowDetails={setDetailsTarget}></Cards>
        <BottomNav
          isTicking={isTicking}
          onClickSleepButton={() => setIsSleepTimerVisible(true)}
          clockText={clockText}
        />

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          ref={playerRef}
          controls
          id="player"
          onTimeUpdate={onTimeUpdate}
          onPlaying={onPlaying}
          onEnded={onEnded}
        ></audio>
      </section>
      <footer id="footer">
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            window.dispatchEvent(
              new KeyboardEvent("keyup", {
                key: "ArrowLeft",
              }),
            )
          }}
          href="#"
          id="left-btn"
        >
          b
        </a>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a href="#" onClick={showPopup} id="popup-btn">
          Popup
        </a>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <a
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            window.dispatchEvent(
              new KeyboardEvent("keyup", {
                key: "ArrowRight",
              }),
            )
          }}
          href="#"
          id="right-btn"
        >
          n
        </a>
        <p>
          An Outreach of{" "}
          <a href="http://www.ligonier.org/" target="_blank" rel="noreferrer">
            Ligonier
          </a>
          <span className="copyright">&copy; {new Date().getFullYear()}</span>
        </p>
      </footer>
      {/*  Modals and Pop Ups */}
      <SleepTimer
        isVisible={isSleepTimerVisible}
        onFinish={stopRadio}
        onClose={() => setIsSleepTimerVisible(false)}
        isTicking={isTicking}
        setIsTicking={setIsTicking}
      />
      <ShareModal />
      <DetailsPopup
        target={detailsTarget}
        setTarget={setDetailsTarget}
        resources={resources}
        shows={shows}
        organizations={organizations}
        schedule={schedule}
        slots={slots}
        playlistIndex={playlistIndex}
      />
    </>
  )
}
