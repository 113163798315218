/* eslint-disable jsx-a11y/anchor-is-valid */
import { useScript } from "@uidotdev/usehooks"

export const ShareModal = () => {
  useScript("https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v18.0")
  useScript("https://platform.twitter.com/widgets.js")

  return (
    <section id="share">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          document.body.className = "radio"
          // trackScreenView("radio")
        }}
        className="close"
      >
        d
      </a>
      <h2 style={{ marginTop: "15px", color: "#000000" }}>Share RefNet</h2>
      <div id="dialog_content">
        <ul id="social_share_list" className="clearfix">
          <li>
            <div
              className="fb-share-button"
              data-href="https://www.facebook.com/RefNet.fm"
              data-layout="button_count"
              data-size="small"
            >
              <a
                target="_blank"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.facebook.com%2FRefNet.fm&amp;src=sdkpreparse"
                className="fb-xfbml-parse-ignore"
                rel="noreferrer"
              >
                Share
              </a>
            </div>
          </li>

          <li>
            <a
              href="https://twitter.com/intent/tweet"
              className="twitter-share-button"
              data-count="vertical"
              data-via="RefNet"
            >
              Tweet
            </a>
          </li>

          <li>
            <div
              className="g-plus"
              data-action="share"
              data-annotation="vertical-bubble"
              data-height="60"
              data-href="http://refnet.fm"
            ></div>
          </li>
        </ul>
      </div>
    </section>
  )
}
