import classNames from "classnames"

interface BottomNavProps {
  onClickSleepButton: CallableFunction
  isTicking: boolean
  clockText: string
}

export function BottomNav({
  onClickSleepButton,
  isTicking,
  clockText,
}: BottomNavProps) {
  return (
    <nav id="bottom">
      <a
        href="http://refnet.fm/schedule"
        id="schedule-btn"
        target="_blank"
        rel="noreferrer"
      >
        l
      </a>
      <span id="clock">{clockText}</span>
      <button
        id="sleeper-btn"
        className={classNames({
          on: isTicking,
        })}
        onClick={(e) => {
          e.preventDefault()
          onClickSleepButton()
        }}
      >
        z
      </button>
    </nav>
  )
}
