import { useEffect, useState } from "react"
import moment from "moment-timezone"

interface Episode {
  title: string
  speaker: string
  duration: string
  resource_url: string
}

interface Show {
  title: string
  description: string
  host: string
  schedule: Array<{ [key: number]: string }>
  links: Array<{ label: string; url: string }>
  organization_id: string
}

interface Org {
  name: string
  description: string
  links: Array<{ label: string; url: string }>
}

interface Resource {
  title: string
  artist: string
  album: string
  speaker: string
  duration: string
  resource_url: string
  slot_id: string
}

export default function DetailsPopup(props: {
  target: HTMLElement | undefined
  setTarget: CallableFunction
  resources: { index: { [key: string]: Resource } }
  shows: { index: { [key: string]: Show } }
  organizations: { index: { [key: string]: Org } }
  schedule: { [key: number]: { songs: Array<{ resource_id: string }> } }
  slots: { [key: number]: number }
  playlistIndex: number
}) {
  const {
    target,
    setTarget,
    resources,
    shows,
    organizations,
    schedule,
    slots,
    playlistIndex,
  } = props
  const [pane, setPane] = useState(0)
  const [episode, setEpisode] = useState<Episode>({} as Episode)
  const [songs, setSongs] = useState<Array<Resource>>([])
  const [show, setShow] = useState<Show>({} as Show)
  const [organization, setOrganization] = useState<Org>({} as Org)

  function resize() {
    const loader = document.getElementById("p_loader")

    if (loader) {
      loader.style.left = (window.innerWidth - loader.offsetWidth) / 2 + "px"
      loader.style.top = (window.innerHeight - loader.offsetHeight) / 2 + "px"
    }
  }

  useEffect(() => {
    setEpisode({} as Episode)
    setShow({} as Show)
    setSongs([])
    setOrganization({} as Org)

    window.addEventListener("resize", resize)

    if (target != undefined) {
      setPane(0)

      // set popup contents
      const header = target.querySelector("h4")

      // todo: assumes show for now
      if (header) {
        const href = header.dataset.href

        if (href && href.substr(0, 10) == "/episodes/") {
          const episodeId = href.slice(10)
          const e = resources.index[episodeId]
          const s = shows.index[e.slot_id.replace("show_", "")]

          if (s == undefined) return

          const org = organizations.index[s.organization_id]

          setEpisode(e as Episode)
          setShow(s)
          setOrganization(org)
        } else if (href && href.substr(0, 7) == "/music/") {
          const id = href.slice(7)

          const e = schedule[+id]
          const s = e.songs.map((song: { resource_id: string }) => {
            return resources.index[song.resource_id]
          })

          setSongs(s)
        } else {
          // Note if we encounter an href we're not handling yet.
          console.log("encountered request for details on", href)
        }
      }

      // show popup (animate)
      const overlay = document.getElementById("p_overlay")
      const loader = document.getElementById("p_loader")
      const bucket = document.getElementById("p_bucket")

      if (overlay && bucket && loader) {
        overlay.style.display = "block"
        bucket.style.display = "block"
        loader.style.display = "block"

        const interval = setInterval(resize, 10)

        setTimeout(() => {
          clearInterval(interval)
        }, 500)

        setTimeout(() => {
          overlay.style.opacity = ".5"
          loader.style.transform = "scale(1)"
          loader.style.opacity = "1"
          // This adds a class that allows the width and height to change based
          // on the breakpoint of the children within the p_loader element
          loader.classList.add("loader_dimensions")
        }, 10)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target])

  function searchQuery(...parts: Array<string>) {
    const args = Array.prototype.slice.call(parts, 0)
    const query = args.join(" ").replace(/ /g, "+")

    return escape(query) + "+audio"
  }

  function close() {
    const overlay = document.getElementById("p_overlay")
    const loader = document.getElementById("p_loader")

    if (overlay && loader) {
      overlay.style.opacity = "0"
      setTimeout(() => {
        overlay.style.display = "none"
      }, 500)

      loader.style.transform = "scale(0)"
      loader.style.opacity = "0"

      setTimeout(() => {
        loader.style.display = "none"
      }, 500)

      setTarget(undefined)
    }
  }

  const showTimes = []

  for (const day in show.schedule) {
    const times = []

    for (const i in show.schedule[day]) {
      const time = show.schedule[day][i]

      times.push(<span key={time}>{time}</span>)
    }

    showTimes.push(
      <div key={day}>
        <dt>{day}</dt>
        <dd>{times}</dd>
      </div>,
    )
  }

  const showLinks = []

  if (show.links && show.links.length) {
    for (const link of show.links) {
      showLinks.push(
        <nav className="link-list" key={link.url}>
          {link.label.toLowerCase() !== "podcast" ? (
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.label}
            </a>
          ) : (
            ""
          )}
        </nav>,
      )
    }
  }

  const orgLinks = []

  if (organization.links) {
    for (const link of organization.links) {
      if (link.label.toLowerCase() !== "podcast")
        orgLinks.push(
          <a key={link.url} href={link.url} target="_blank" rel="noreferrer">
            {link.label}
          </a>,
        )
    }
  }

  let episodeBlock = <></>

  if (!songs.length) {
    episodeBlock = (
      <>
        <div className="pane">
          <h1>Episode</h1>

          <dl className="episode">
            <dt>TITLE</dt>
            <dd>{episode.title}</dd>
            <dt>BY</dt>
            <dd>{episode.speaker === "" ? show.host : episode.speaker}</dd>
            <dt>LENGTH</dt>
            <dd>
              {moment("2015-01-01")
                .startOf("day")
                .seconds(+episode.duration)
                .format("H:mm:ss")}
            </dd>
          </dl>

          <h4>Where can I get this episode?</h4>

          {episode.resource_url ? (
            <a
              href={episode.resource_url}
              target="_system"
              className="get-resource"
            >
              AVAILABLE ONLINE
            </a>
          ) : (
            <a
              href={`http://google.com/search?q=${searchQuery(
                episode.title,
                episode.speaker || show.host,
              )}`}
              target="_system"
              className="get-resource"
            >
              SEARCH ONLINE
            </a>
          )}

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="next"
            onClick={(e) => {
              e.preventDefault()
              setPane(pane + 1)
            }}
          >
            About this show
          </a>
        </div>

        <div className="pane">
          <h1>{show.title}</h1>

          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: show.description }}
          ></div>
          <div className="clear">&nbsp;</div>

          <dl className="showtimes">{showTimes}</dl>

          {showLinks}

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            className="next"
            onClick={(e) => {
              e.preventDefault()
              setPane(pane + 1)
            }}
          >
            About this organization
          </a>
        </div>

        <div className="pane">
          <h1>{organization.name}</h1>

          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: organization.description }}
          ></div>

          <nav className="link-list">{orgLinks}</nav>
        </div>
      </>
    )
  }

  useEffect(() => {
    const details = document.getElementById("details")

    if (details) {
      if (pane == 1) details.className = "two"
      else if (pane == 2) details.className = "three"
      else details.className = ""
    }
  }, [pane])

  let musicBlock = <></>

  function showSong(i: number) {
    const songDivs = document.querySelectorAll("#songs > div")

    if (songDivs) {
      for (const song of songDivs) song.classList.remove("show")

      songDivs[+i].classList.add("show")
      setPane(pane + 1)
    }
  }

  // todo: test and verify that the live track is marked as such
  function trackClass(i: number) {
    const slotsDiv = document.getElementById("slots")

    if (slotsDiv) {
      const index = Array.prototype.indexOf.call(slotsDiv.children, target)
      const musicIndex = playlistIndex - slots[index]

      return musicIndex == i ? "live" : ""
    }
  }

  if (songs.length) {
    const tracks = []
    const songList = []

    for (const i in songs) {
      const song = songs[+i]

      tracks.push(
        <span key={i}>
          <dt>{+i + 1}</dt>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
          <dd
            data-index={+i}
            className={trackClass(+i)}
            onClick={() => {
              showSong(+i)
            }}
          >
            {song.title}
          </dd>
        </span>,
      )

      songList.push(
        <div key={i}>
          <h1>Track {i + 1}</h1>

          <dl className="episode">
            <dt>TITLE</dt>
            <dd>{song.title}</dd>

            <dt>ARTIST</dt>
            <dd>{song.artist}</dd>

            <dt>ALBUM</dt>
            <dd>{song.album}</dd>

            <dt>LENGTH</dt>
            <dd>
              {moment("2015-01-01")
                .startOf("day")
                .seconds(+song.duration)
                .format("H:mm:ss")}
            </dd>
          </dl>

          <h4>Where can I get this song?</h4>

          {song.resource_url ? (
            <a
              href={song.resource_url}
              target="_system"
              className="get-resource"
            >
              AVAILABLE ONLINE
            </a>
          ) : (
            <a
              href={`http://google.com/search?q=${searchQuery(
                song.artist,
                song.album,
                song.title,
              )}`}
              target="_system"
              className="get-resource"
            >
              SEARCH ONLINE
            </a>
          )}
        </div>,
      )
    }

    musicBlock = (
      <>
        <div className="pane">
          <h1>MUSIC</h1>

          <dl className="tracks">{tracks}</dl>
        </div>

        <div id="songs" className="pane">
          {songList}
        </div>
      </>
    )
  }

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        id="p_overlay"
        onClick={close}
        style={{
          width: "100%",
          height: "100%",
          display: "none",
          opacity: "0",
          transitionDuration: ".3s",
        }}
      ></div>
      {/* todo: should reposition to source link when activated */}
      <div
        id="p_loader"
        style={{
          transitionDuration: ".3s",
          transform: "scale(0)",
        }}
      >
        <div id="p_bucket" style={{ opacity: "1" }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a id="p_close" href="#" style={{ opacity: 0, width: 0, height: 0 }}>
            &times;
          </a>

          <section id="details">
            <nav id="details-nav">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={close} className="close">
                d
              </a>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                href="#"
                className="back two"
                onClick={() => {
                  setPane(pane - 1)
                }}
              >
                b
              </a>
            </nav>
            {episodeBlock}
            {musicBlock}
          </section>
        </div>
      </div>
    </>
  )
}
