import type { Moment } from "moment-timezone"
import moment from "moment-timezone"
import React, { useEffect, useState } from "react"
import classNames from "classnames"

interface SleepTimerProps {
  isVisible: boolean
  onClose: CallableFunction
  onFinish: CallableFunction
  isTicking: boolean
  setIsTicking: CallableFunction
}

export const SleepTimer = ({
  isVisible,
  onClose,
  onFinish,
  isTicking,
  setIsTicking,
}: SleepTimerProps) => {
  const [endDateTime, setEndDateTime] = useState<Moment>()
  const [duration, setDuration] = useState("0")
  const [remainingTime, setRemainingTime] = useState("")
  const [timerInterval, setTimerInterval] = useState<ReturnType<
    typeof setInterval
  > | null>(null)

  const durationIsGreaterThanZero = Number(duration) > 0

  function getRemainingTime(end: Moment) {
    const remaining = moment.utc(+moment.duration(end?.diff(moment())))
    let remainingTime = ""

    if (remaining.hours()) {
      remainingTime = remaining.format("H:mm:ss")
    } else if (remaining.minutes()) {
      remainingTime = remaining.format("m:ss")
    } else {
      remainingTime = remaining.format("s")
    }

    return remainingTime
  }

  function close(e: React.MouseEvent) {
    e.preventDefault()

    const isDurationSetWithInactiveTimer =
      !isTicking && durationIsGreaterThanZero

    if (isDurationSetWithInactiveTimer) {
      setDuration("0")
    }
    onClose()
  }

  function handleSliderChange(e: React.FormEvent<HTMLInputElement>) {
    setDuration(e.currentTarget.value)
  }

  function handleStart(e: React.MouseEvent) {
    if (e) e.preventDefault()

    const durationIsGreaterThanZero = duration !== "0" || Number(duration) > 0

    if (durationIsGreaterThanZero) {
      const end = moment().utc().add(duration, "minutes")
      setEndDateTime(end)
      setRemainingTime(getRemainingTime(end))
      setIsTicking(true)
    }
  }

  function handleStop(e?: React.MouseEvent) {
    if (e != undefined) e.preventDefault()

    // clear the interval when we stop
    if (timerInterval) {
      clearInterval(timerInterval)
    }

    setDuration("0")
    setIsTicking(false)
  }

  useEffect(() => {
    if (endDateTime === undefined) return
    const interval = setInterval(() => {
      const remainingTime = getRemainingTime(endDateTime)
      if (remainingTime == "0") {
        handleStop()
        onFinish()
        clearInterval(interval)
      }

      setRemainingTime(remainingTime)
    }, 1000)

    // we set the interval so we can clear it in the handleStop function
    setTimerInterval(interval)

    return () => {
      clearInterval(interval)
    }

    // TODO: refactor this. Not having all the deps can lead to weird bugs
    // For now, let's make sure this useEffect ONLY tracks the endDateTime and nothing
    // else
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateTime])

  return (
    <>
      <section
        className={classNames({
          sleeper: true,
          set: durationIsGreaterThanZero,
          "sleeper-visible": isVisible,
          ticking: isTicking,
          dragging: durationIsGreaterThanZero && !isTicking,
        })}
      >
        <h1 id="headline">{isTicking ? "Sleep Timer" : "Set Sleep Timer"}</h1>
        <span id="duration">{isTicking ? remainingTime : duration}</span>
        <input
          id="slider"
          type="range"
          name="points"
          min="0"
          max="120"
          value={duration}
          onChange={handleSliderChange}
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="close" href="#" onClick={close}>
          Close
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="cancel" href="#" onClick={handleStop}>
          Cancel
        </a>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={classNames({
            start: true,
            on: isTicking,
          })}
          href="#"
          onClick={handleStart}
        >
          Start
        </a>
      </section>
      <div
        className={classNames({ overlay: true, "overlay-visible": isVisible })}
      >
        &nbsp;
      </div>
    </>
  )
}
